import Aos from "aos";
import React from "react";
import TextParagraf from "../text/TextParagraf";

export default function AboutUs() {
  Aos.init();

  return (
    <div
      className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 md:p-16"
      id="about-us"
      data-aos="fade-up"
    >
      <div className="p-6">
        <div className="flex">
          <p
            className="rounded-xl px-2 bg-blue-400 p-1"
            style={{ color: "#0094D2", background: "#D2F2FF" }}
          >
            ABOUT US
          </p>
        </div>
        <h1 className="font-bold text-3xl py-3">About MLCA Bali</h1>
        <TextParagraf
          text={
            "Which was founded by Ida Bagus Surya Prabhawa M., S.H in 2014, is committed to providing consulting services and legal assistance representing individuals, business entities, and government agencies in carrying out legal actions, both litigation and non-litigation."
          }
        />
        <TextParagraf
          text={
            "One stop legal service is the design of our office in collaboration with Legal Consultants, Notaries, Tax Accountants, Public Accountants so as to be able to provide one stop complete for individuals and business entities."
          }
        />
        <TextParagraf
          text={
            "Our lawyers and legal consultants are members of the Indonesia Advocates Association (PERADI), an organization of lawyer with diverse legal backgrounds and professional networks."
          }
        />
      </div>
      <div>
        <img
          className="h-4/5"
          src="/assets/profile/profile2.png"
          alt="profile2"
        />
      </div>
    </div>
  );
}
