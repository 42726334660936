import Aos from "aos";
import React, { useState } from "react";
import { MailIcon } from "@heroicons/react/outline";
import axios from "axios";

export const SectionHero = () => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");
  const baseAPI = "";
  Aos.init();

  const handleSubmit = () => {
    if (email === "") {
      console.log("Passed");
    } else {
      axios
        .post(baseAPI + "/v1/contact/email", {
          email: email,
        })
        .then(function (response) {
          setResponse("Email berhasil dikirimkan");
        })
        .catch(function (error) {
          setResponse(error);
        });
    }
  };
  return (
    <div className="md:mx-48" data-aos="fade-up">
      <div
        className="font-poppins container mx-auto flex items-center justify-center my-20 rounded-3xl bg-no-repeat bg-cover"
        style={{ backgroundImage: "url('/assets/home/background-hero.png')" }}
      >
        <div className="container justify-center text-center text-white flex flex-col items-center my-28">
          <div className="flex">
            <p className="rounded-xl bg-mlca-primary-blue px-2 bg-blue-400 p-1">
              Welcome to
            </p>
          </div>
          <p className="font-bold pt-3 text-6xl">
            Mantra Legal Consultant & Advocates
          </p>
          <p className="font-normal text-xl pt-5 w-3/5 text-light-gray">
            One stop legal service comes from Bali which is able to provide one
            stop complete for individuals and business entities.
          </p>
          <form
            className="pt-4 flex md:flex-row sm:flex-col w-1/2"
            onSubmit={handleSubmit}
          >
            <label class="relative w-full">
              <span class="absolute left-0 top-2 flex items-center pl-2">
                <MailIcon className="h-5 w-5 text-gray-text" />
              </span>
              <input
                className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-mlca-primary-blue"
                id="Email"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter your email"
              ></input>
            </label>
            <div className="bg-mlca-primary-blue mx-3 w-1/2 rounded-md">
              <button className="py-2" type="submit">
                Get in Touch
              </button>
            </div>
          </form>
          <p className="font-normal text-sm pt-2 w-2/5 text-light-gray">
            Write your email and immediately get in touch with us.
          </p>
        </div>
      </div>
    </div>
  );
};
