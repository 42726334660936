import Aos from "aos";
import React from "react";

export const Clients = () => {
  Aos.init();

  return (
    <div className="my-10" data-aos="fade-up">
      <div className="container mx-auto flex items-center justify-center font-poppins">
        <p className="text-base text-medium text-light-gray2 text-center">
          Trusted by the top companies in this industry
        </p>
      </div>
      <div className="container mx-auto grid md:grid-cols-7 sm:grid-cols-1 items-center justify-center my-5 md:px-52">
        <div className="flex items-center justify-center md:mb-0 sm:mb-10">
          <img src="/assets/clients/mahaputra.png" className="" alt="client" />
        </div>
        <div className="flex items-center justify-center mb-20 md:mb-0">
          <img
            src="/assets/clients/the-king-group.png"
            className=""
            alt="client"
          />
        </div>
        <div className="flex items-center justify-center mb-20 md:mb-0">
          <img src="/assets/clients/ack.png" className="" alt="client" />
        </div>
        <div className="flex items-center justify-center mb-20 md:mb-0">
          <img
            src="/assets/clients/bankkhrisna.png"
            className=""
            alt="client"
          />
        </div>
        <div className="flex items-center justify-center mb-20 md:mb-0">
          <img src="/assets/clients/acc.png" className="" alt="client" />
        </div>
        <div className="flex items-center justify-center mb-20 md:mb-0">
          <img src="/assets/clients/logo-api.png" className="" alt="client" />
        </div>
        <div className="flex items-center justify-center">
          <img
            src="/assets/clients/santi-mandala.png"
            className=""
            alt="client"
          />
        </div>
      </div>
    </div>
  );
};
