import React from "react";
import Navbar from "../component/navbar/Navbar";
import AboutUs from "../component/section/AboutUs";
import Section3C from "../component/section/Section3C";
import { SectionHero } from "../component/section/SectionHero";
import ServiceB from "../component/section/ServiceB";
import { ServiceA } from "../component/section/ServiceA";
import { Clients } from "../component/section/Clients";
import { Footer } from "../component/section/Footer";

export default function Homepage() {
  return (
    <div className="">
      <Navbar />
      <SectionHero />
      <Clients />
      <AboutUs />
      <ServiceA />
      <ServiceB />
      <Section3C />
      <Footer />
    </div>
  );
}
