import Aos from "aos";
import React from "react";
import Badge from "../component/badge/Badge";
import Card from "../component/card/Card";
import Navbar from "../component/navbar/Navbar";

export default function OurTeam() {
  const teamData = [
    {
      src: "/assets/profile/profile-2.png",
      name: "Ni Made Anggreaningsih, SH.",
      role: "Advocate Team",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "Dede Ismail Habibi, SH.",
      role: "Advocate Team",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "W. Ida Junana, SH.",
      role: "Advocate Team",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "Ida Bagus Surya Prabhawa M., SH.",
      role: "Legal Consultant Team",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "I Made Budiantara",
      role: "Legal Consultant Team",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "I Ketut Sania",
      role: "Population Consultant Team",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "I Made Budiana",
      role: "Population Consultant Team",
    },
  ];
  const expertTeam = [
    {
      src: "/assets/profile/profile-2.png",
      name: "Dr. I Gusti Ketut Aryawan, SH., MH. ",
      role: "Criminal Law Expert",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "Dr. I Ketut Westra, SH., MH.",
      role: "Civil Law Expert",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "Dr. I Wayan Wiryawan, SH., MH. ",
      role: "Civil Law Expert",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "Dr. I Putu Arya Sumertayasa, SH., MH. ",
      role: "State Administrative Law Expert",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "Dr. I Wayan Gde Wiryawan, SH., MH. ",
      role: "Employment Law Expert",
    },
  ];
  const ourPartner = [
    {
      src: "/assets/profile/profile-2.png",
      name: "I Made Suryawan SH., MH. ",
      role: "Advocate & Legal Consultant ",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "I Gede Parta Wijaya., SH.",
      role: "Advocate & Curator, Legal Consultant ",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "Ida Bagus Agung Sidi Mantra, SH., M.Kn ",
      role: "Notary Public",
    },
    {
      src: "/assets/profile/profile-2.png",
      name: "A.A. Ayu Dhiah Tri Setiawati, SH., M.Kn",
      role: "Notary Public",
    },
  ];

  Aos.init();
  return (
    <div>
      <Navbar />
      <div className="sm:px-10 xl:px-24 ">
        <div className="my-10" id="mlca-team">
          <Badge title="OUR TEAMS" />
          <h1 className="font-bold text-5xl mt-6">MCLA Team</h1>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-1 my-5 gap-10 ">
          {teamData.map((item) => (
            <Card name={item.name} src={item.src} role={item.role} />
          ))}
        </div>
        <div className="my-20" id="expert-team">
          <h1 className="font-bold text-5xl mt-6">Expert Team</h1>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-1  my-5 gap-10">
          {expertTeam.map((item) => (
            <Card name={item.name} src={item.src} role={item.role} />
          ))}
        </div>
        <div className="my-20" id="our-partner">
          <h1 className="font-bold text-5xl mt-6">Our Partner</h1>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-1  my-5 gap-10">
          {ourPartner.map((item) => (
            <Card name={item.name} src={item.src} role={item.role} />
          ))}
        </div>
      </div>
    </div>
  );
}
