import Aos from "aos";
import React from "react";
import TextParagraf from "../text/TextParagraf";

export default function Section3C() {
  Aos.init();

  return (
    <div
      className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 md:p-16 place-items-center"
      data-aos="fade-up"
    >
      <div className="p-6">
        <div className="flex">
          <p
            className="rounded-xl px-2 bg-blue-400 p-1"
            style={{ color: "#0094D2", background: "#D2F2FF" }}
          >
            SCOPE OF LEGAL SERVICE
          </p>
        </div>
        <h1 className="font-bold text-6xl py-3">Litigation Legal Services</h1>
        <TextParagraf
          text="Litigation legal services include legal actions related to processes at the Court, Attorney General Office, and the Police as well as other legal actions that will be carried out by clients related to transaction disputes with other parties (individuals, companies or the other institutions).
"
        />
      </div>
      <div>
        <img src="/assets/home/section-3-c.png" alt="profile" />
      </div>
    </div>
  );
}
