import Aos from "aos";
import React from "react";
import Badge from "../badge/Badge";
import TextParagraf from "../text/TextParagraf";

export default function ServiceB() {
  Aos.init();

  return (
    <div
      className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 md:p-16 place-items-center"
      data-aos="fade-up"
    >
      <div>
        <img src="/assets/home/service-b.png" alt="profile" />
      </div>
      <div className="p-6">
        <Badge title="SCOPE OF LEGAL SERVICE" />
        <h1 className="font-bold text-6xl py-3">Legal Services per Case</h1>
        <TextParagraf text="Our Services also include providing special assistance to clients who need to make transactions with complex and time-consuming processes. We will make a separate offer to covering the scope of work, fees and payment procedures." />
      </div>
    </div>
  );
}
