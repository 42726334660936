import React from "react";

export const Footer = () => {
  return (
    <div className="mt-20 bg-dark-gray font-poppins">
      <div className="py-5">
        <div className="container mx-auto flex items-center justify-center md:w-4/5 md:h-2/4 py-5">
          <img src="logo mlca 1.png" alt="logo" />
        </div>
        <ul className="text-xl font-medium text-light-gray grid md:grid-cols-5 sm:grid-cols-1 my-5 md:mx-72 justify-center align-middle text-center">
          <li className="px-5 sm:mx-5">
            <a href="/#about-us">About Us</a>
          </li>
          <li className="px-5">
            <a href="/#service">Service</a>
          </li>
          <li className="px-5">
            <a href="/our-team#mlca-team">MLCA Team</a>
          </li>
          <li className="px-5">
            <a href="/our-team/#expert-team">Expert Team</a>
          </li>
          <li className="px-5">
            <a href="/our-team/#our-team">Our Partner</a>
          </li>
        </ul>
        <hr />
        <p className="text-xl font-medium text-light-gray py-5 text-center">
          @2022 MLCA. All rights reserved.
        </p>
      </div>
    </div>
  );
};
