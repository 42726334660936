import React from "react";

export default function Badge({ title }) {
  return (
    <div className="flex">
      <p
        className="rounded-xl px-2 bg-blue-400 p-1"
        style={{ color: "#0094D2", background: "#D2F2FF" }}
      >
        {title}
      </p>
    </div>
  );
}
