import React from "react";
import { AiOutlineMenu } from "react-icons/ai";

export default function Navbar() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <img src="/assets/logo/logo-mlca-1.png" alt="logo" />
          <button
            type="button"
            className="text-xl leading-none px-3 py-1 block lg:hidden outline-none focus:outline-none"
            onClick={handleOpen}
          >
            <AiOutlineMenu />
          </button>
        </div>
        <div
          className={"lg:flex flex-grow  " + (open ? "flex" : "hidden")}
          id="example-navbar-danger"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li>
              <a
                href="/#about-us"
                className="px-4 py-3 flex items-center leading-snug text-gray-text text-lg hover:opacity-75"
              >
                About us
              </a>
            </li>
            <li>
              <a
                href="/#service"
                className="px-4 py-3 flex items-center leading-snug text-gray-text text-lg hover:opacity-75"
              >
                Service
              </a>
            </li>
            <li>
              <a
                href="/our-team"
                className="px-4 py-3 flex items-center leading-snug text-gray-text text-lg hover:opacity-75"
              >
                Our Team
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
