import Aos from "aos";
import React from "react";
import Badge from "../badge/Badge";
import Badge2 from "../badge/Badge2";

export const ServiceA = () => {
  Aos.init();
  return (
    <div className="my-10 p-5 md:p-16" id="service">
      <div className="container md:px-16 flex items-center justify-center">
        <div className="grid md:grid-cols-5 sm:grid-cols-1 font-poppins gap-4 p-6">
          <div className="col-span-2 flex">
            <div className="flex-auto">
              <Badge title="SCOPE OF LEGAL SERVICE" />
              <p className="text-5xl font-bold pt-3">
                Legal Service in General
              </p>
              <p className="font-medium text-xl pt-7 text-gray-text">
                We cover several aspects of the legal field related to daily
                company activities , especially in company transactions, with
                the following scope of work.
              </p>
            </div>
          </div>
          <div className="col-span-3 flex items-center justify-center">
            <img src="/assets/profile/serviceA.png" alt="profile2" />
          </div>
        </div>
      </div>
      <div className="container md:px-20 flex items-center justify-center">
        <div className="grid md:grid-cols-3 sm:grid-row-1 font-poppins gap-x-52 md:grid-flow-row gap-10 md:gap-y-20 md:gap-x-36">
          <div>
            <Badge2 title="1" />
            <p className="font-semibold text-xl">
              Provide legal advice and consultation.
            </p>
          </div>
          <div>
            <Badge2 title="2" />
            <p className="font-semibold text-xl">Provide legal opinion.</p>
          </div>
          <div>
            <Badge2 title="3" />
            <p className="font-semibold text-xl">
              Conduct research on legal facts.
            </p>
          </div>
          <div>
            <Badge2 title="4" />
            <p className="font-semibold text-xl">
              Prepare drafts and/or revise concepts required by the company for
              business correspondence.
            </p>
          </div>
          <div>
            <Badge2 title="5" />
            <p className="font-semibold text-xl">
              Provide information and knowledge to build understanding,
              including legal opinions on new laws, regulations and provisions
              issued by the Government.
            </p>
          </div>
          <div>
            <Badge2 title="6" />
            <p className="font-semibold text-xl">
              Reviewing agreements and contracts and standard forms that the
              company has used, including preparing amandement documents related
              to agreements and contracts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
