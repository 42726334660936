import React from "react";

export default function Card({ src, name, role }) {
  return (
    <div className="my-3" data-aos="fade-up">
      <img src={src} alt={name} width="100%" />
      <h2 className="font-semibold text-3xl my-3 leading-10">{name}</h2>
      <h3 className="text-lg " style={{ color: "#0094D2" }}>
        {role}
      </h3>
    </div>
  );
}
