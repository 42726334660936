import React from "react";

export default function Badge2({ title }) {
  return (
    <div className="flex">
      <p className="rounded-full mb-4 py-2 px-4 bg-mlca-primary-blue p-1 text-white font-poppins font-semibold text-xl">
        {title}
      </p>
    </div>
  );
}
